@use '../../commons/colors';

.analysis-tab {
  display: flex;
  flex-direction: column;
  gap: 15px;
  float: left;
  width: 100%;
  padding: 20px;

  .analysis-value-not-saved,
  .analysis-value-not-saved span,
  .analysis-value-not-saved input {
    color: colors.$translatedBlue;
  }

  &.settings-panel-contentwrapper-tab-background {
    text-align: left;
    padding: 10px 20px 20px;
  }

  span {
    display: block;
    text-align: left;
    color: colors.$grey6;
  }
  .analysis-tab-head {
    span {
      font-size: 16px;
      line-height: 24px;
    }
  }

  input.input-percentage {
    height: 40px;
    width: 76px;
    border: 1px solid colors.$grey4;
    padding: 8px 12px 8px 12px;
    box-shadow: 2px 2px 4px 0px #00000014 inset;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    display: block;
  }

  .analysis-tab-switchContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 0px;
    h3 {
      margin-bottom: 0;
    }
  }
  .analysis-tab-tableContainer {
    table {
      border: 1px solid colors.$grey3;
      border-spacing: 0;
      border-collapse: separate;
      border-radius: 4px;
      overflow: hidden;
      th:not(:last-child),
      td:not(:last-child) {
        border-right: 1px solid colors.$grey3;
      }
      thead > tr > th {
        border-bottom: 1px solid colors.$grey3;
      }
      th {
        height: 64px;
        padding: 0 12px;
      }
      td {
        height: 56px;
      }
      th,
      td {
        width: 94px;
        background-color: colors.$white;
      }
    }
    input.input-percentage {
      display: block;
      margin: 0 auto;
    }
  }
  .analysis-tab-exceptionsContainer {
    background: colors.$white;
    border: 1px solid colors.$grey3;
    padding: 24px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .analysis-tab-subhead .input-percentage {
      font-size: 16px;
      line-height: 24px;
      width: 128px;
      margin-top: 24px;
    }
    h3,
    span {
      font-size: 16px;
      line-height: 24px;
      padding: 0;
    }
    h3 {
      margin-bottom: 8px;
    }
    .analysis-tab-exceptions {
      padding-top: 24px;
      gap: 16px;
      display: flex;
      flex-direction: column;
      input.input-percentage {
        width: 80px;
      }
      .analysis-tab-exceptionsRows {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .analysis-tab-exceptionsRow {
        display: flex;
        align-items: center;
        gap: 24px;
        .select-with-label__wrapper {
          height: 40px;
          width: 275px;
          .custom-dropdown {
            margin-top: 0;
            padding-top: 12px;
            border-radius: unset;
            background-color: white;
          }
          .select {
            font-size: 16px;
            padding: 9px 46px 9px 12px;
            border-radius: 2px;
            border: 1px solid rgba(34, 36, 38, 0.15);
            box-shadow: inset 0 1px 3px #ddd;
          }
          .dropdown__search-bar {
            height: 34px;
            margin: 0 12px 12px;
            border-radius: 4px;
            border: 1px solid rgba(34, 36, 38, 0.15);
            background-color: #fff;
            outline: none;
            .dropdown__search-bar-input {
              width: 100%;
              padding: 8px 8px;
              background-color: unset;
              outline: none;
            }
          }
        }
        #swaplang {
          margin: 0 10px;
          cursor: pointer;
        }
        > div {
          display: flex;
          align-items: center;
          .button {
            height: 32px;
            width: 106px;
            font-size: 16px;
            padding: 0px;
            &.close-button {
              width: 32px;
              margin-left: 8px;
            }
          }
        }
      }
      .analysis-tab-buttons {
        gap: 8px;
        button {
          height: 32px;
        }
      }
      button.add-button {
        width: 148px;
      }
    }
  }
}
