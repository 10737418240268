@use '../commons/variables';
/******* Notifications ************/

.notifications-position {
  font-family: inherit;
  position: fixed;
  width: variables.$notifications-width;
  padding: 0 10px 10px 10px;
  z-index: 99999999;
  @include variables.box-sizing(border-box);
  height: auto;
}
.notifications-position-bl {
  @extend .notifications-position;
  top: auto;
  bottom: 30px;
  left: 20px;
  right: auto;
}
.notifications-position-bc {
  @extend .notifications-position;
  top: auto;
  bottom: 30px;
  margin: 0 auto;
  left: 50%;
  margin-left: calc(-1 * (variables.$notifications-width / 2));
}
.notifications-position-br {
  @extend .notifications-position;
  top: auto;
  bottom: 30px;
  left: auto;
  right: 0px;
}
.notifications-position-tl {
  @extend .notifications-position;
  top: 60px;
  bottom: auto;
  left: 0px;
  right: auto;
}
.notifications-position-tc {
  @extend .notifications-position;
  top: 60px;
  bottom: auto;
  margin: 0 auto;
  left: 50%;
  margin-left: calc(-1 * (variables.$notifications-width / 2));
}
.notifications-position-tr {
  @extend .notifications-position;
  top: 60px;
  bottom: auto;
  left: auto;
}

.notification-type {
  position: relative;
  width: 100%;
  text-align: left;
  background-color: #fff;
  @include variables.border-radius(2px);
  font-size: 16px;
  margin: 10px 0 0;
  padding: 15px;
  box-shadow: 0 1px 10px #666;
  display: block;
  @include variables.box-sizing(border-box);
  opacity: 0;
  @include variables.transition(0.3s ease-in-out);
  .notification-message {
    word-wrap: break-word;
  }
}

.notification-type-success {
  @extend .notification-type;
  border-top: 3px solid variables.$notification-success;
}

.notification-type-error {
  @extend .notification-type;
  border-top: 3px solid variables.$notification-error;
}

.notification-type-warning {
  @extend .notification-type;
  border-top: 3px solid variables.$notification-warning;
}

.notification-type-info {
  @extend .notification-type;
  border-top: 3px solid variables.$notification-info;
}
.notification-message {
  .warning-call-to {
    text-transform: uppercase;
    margin: 5px 0 0;
    text-align: right;
    a {
      text-decoration: underline;
      color: #4183c4;
      font-weight: 700;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.notification-close-button {
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 14px;
  background-color: #333;
  color: #ffffff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #666;
  }
}

.notification-title {
  font-size: 18px;
  margin: 0 0 7px 0;
  padding: 0;
  font-weight: bold;
}

.notification-title-success {
  @extend .notification-title;
  color: variables.$notification-success;
}
.notification-title-error {
  @extend .notification-title;
  color: variables.$notification-error;
}
.notification-title-warning {
  @extend .notification-title;
  color: variables.$notification-warning;
}
.notification-title-info {
  @extend .notification-title;
  color: variables.$notification-info;
}

.notification-message{
  a {
    color:  variables.$notification-info;
  }
  a.bold {
    font-weight: bold;
  }
}

.notifications-wrapper-inside {
  .translator-notification-sent {
    font-weight: bold;
    line-height: 28px;
    span {
      color: #4183c4;
    }
  }
}
