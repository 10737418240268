@use 'modals/instructionsModal';
@use 'modals/tmShareModal';
@use 'modals/PreferenceModal';
@use "commons/colors";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Calibri', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

h2 {
  font-size: 22px;
}

a {
  font-family: 'Calibri', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Calibri', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

/***********************/
/***** Login Footer ****/

#logoutlink,
.reset-password {
  margin-bottom: 10px;
  text-decoration: underline;
  float: left;
  cursor: pointer;
  color: colors.$linkBlue;

  &:hover {
    text-decoration: none;
    color: colors.$linkBlueHover;
  }
}

.success-modal,
.fatal-error-modal {
  padding: 20px;
}

.validation-error {
  text-align: left;
  float: left;
  color: red;
}

.user-info-form,
.user-reset-password,
.user-gdrive {
  margin: 0 auto;
  width: 100%;
  float: left;
  position: relative;
}

.user-reset-password {
  width: 100%;
}

.forgot-password {
  cursor: pointer;
  text-decoration: underline;
  color: colors.$linkBlue;
}

.dqf-modal input[type='text'],
.dqf-modal input[type='password'],
.login-container-left input,
.register-form-container input[type='text'],
.register-form-container input[type='password'],
.forgot-password-modal input,
.reset-password-modal input,
.user-info-form input {
  margin-top: 5px;
  font-size: 14px;
  width: 100%;
  padding: 0.7em 0.6em;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 15px;
  color: #333;
}

.dqf-modal input[disabled] {
  color: #999;
  cursor: not-allowed;
  background: colors.$grey2;
}

.user-info-form input {
  margin-top: 5px;
  width: 100%;
  color: #000;
}

.login-button,
.register-button,
.register-submit,
.send-password-button,
.reset-password-button {
  width: 160px;
  margin-left: 0px;
}

.login-form-container,
.register-form-container {
  margin: 0 auto;
}

.login-form-container {
  width: 245px;
}

.forgot-password-modal,
.reset-password-modal {
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}

.forgot-password-modal {
  background: url(/public/img/matecat_watch-left-border.png) no-repeat -34px
    136px;
  background-size: 23%;
  /*width: 385px;*/
  padding: 25px 96px;
  text-align: right;
}

.forgot-password-modal p {
  text-align: left;
}

.reset-password-modal {
  padding: 5% 10%;
  width: 80%;
}

.preference-modal-message {
  box-shadow: 0 2px 2px #e2e2e2;
  border-radius: 2px;
  border: 1px solid #ccc;
  line-height: 20px;
  padding: 10px 15px;
  background: rgb(195, 224, 195);
  /*margin-top: 20px;*/
  margin-bottom: 10px;
}

.button-loader {
  background: url(/public/img/loader.gif) center center no-repeat;
  width: 20px;
  height: 20px;
  visibility: hidden;
  position: absolute;
  background-size: 20px 20px;
  left: 15px;
  opacity: 0;
}

.button-loader.show {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}

/***********************/

/********Modal window ****/
.matecat-modal,
.matecat-modal-overlay {
  display: block;
  z-index: 12;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  p {
    line-height: 25px;
  }

  .matecat-modal-header {
    text-align: left;
    min-height: 50px;
    border-radius: 4px 4px 0 0;
    overflow: visible;
    max-height: inherit;
    font-size: 23px;
    background: #002b5c;
    padding: 7px 10px 7px 24px;
    background-size: 35px;
    color: #fff;
    margin: 0 !important;
    display: grid;
    grid-template-columns: 40px 1fr 24px;
    position: relative;
    grid-column-gap: 16px;
    align-items: center;

    h2 {
      margin: 0 !important;
      line-height: 30px;
      font-size: 24px;
      width: auto !important;
      float: none !important;
      font-family: Calibri, Arial, Helvetica, sans-serif;
      font-weight: normal;
    }
  }

  .modal-logo {
    background-size: inherit;
    background: url(/public/img/logo_matecat_small_white.svg) no-repeat;
    background-position-y: center;
    background-size: contain;
    width: 35px;
    height: 35px;
  }

  /* Modal Body */
  .matecat-modal-body {
    min-height: 50px;
    height: 100%;
    /*color: #000;*/
    background-color: colors.$grey5;
    border-radius: 0 0 4px 4px;
    margin: 0 auto;
    overflow: hidden;
    text-align: left;

    h1 {
      text-align: center;
    }
  }

  /* Modal Footer */
  .matecat-modal-footer {
    padding: 2px 16px;
    color: black;
    text-align: left;
    min-height: 30px;
  }

  /* Modal Content */
  .matecat-modal-content {
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    border-radius: 4px;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    text-align: center;
    max-width: 640px;
    min-width: 400px;
    z-index: 2;
    .half-form-left {
      margin-right: 4%;
    }

    .half-form {
      width: 48%;
      float: left;
    }
  }
  .matecat-modal-background {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
  }
}

.matecat-modal-overlay {
  display: block;
  z-index: 13;
  position: fixed;
  left: 6px;
  bottom: 48px;
  top: unset;
  width: 325px;
  height: 342px;
  overflow: auto;
  background-color: unset;

  .modal-logo {
    width: 34px;
  }

  .matecat-modal-content {
    width: 320px;
    min-width: unset;
    box-shadow:
      0 0 0 #e0e0e0,
      0 0 2px rgba(0, 0, 0, 0.12),
      0 2px 4px rgba(0, 0, 0, 0.24) !important;
  }

  .matecat-modal-header {
    text-align: left;
    min-height: 32px;
    border-radius: 4px 4px 0 0;
    overflow: visible;
    max-height: inherit;
    background: #002b5c;
    padding: 6px 10px 3px 24px;
    background-size: 31px;
    color: #fff;
    margin: 0 !important;
    display: grid;
    grid-template-columns: 35px 1fr 24px;
    position: relative;
    grid-column-gap: 16px;

    h2 {
      font-size: 21px;
    }
  }

  .matecat-modal-body {
    h1 {
      font-size: 1.7rem;
    }

    .matecat-modal-textarea {
      padding: 0;
    }

    .matecat-modal-middle {
      padding: 0 20px;
    }

    .matecat-modal-top,
    .matecat-modal-bottom {
      padding: 15px 17px;
    }

    .ui.button {
      font-size: 15px;
    }

    .ui.button.cancel-button {
      //margin-right: 45px;
    }
  }
}

.user-link {
  float: left;
  clear: both;
  font-size: 16px;
  margin-left: 66px;
  height: 20px;
  margin-top: 12px;
  display: flex;
  gap: 16px;
}

/* The Close Button */
.close-matecat-modal {
  color: #fff;
  float: right;
  font-size: 20px;
  font-weight: bold;
}

.close-matecat-modal:hover,
.close-matecat-modal:focus {
  color: red;
  text-decoration: none;
  cursor: pointer;
}

/* buttons */

.matecat-modal-content .disabled,
.matecat-modal-content .disabled:hover,
.matecat-modal-content .disabled:active {
  opacity: 0.5 !important;
  cursor: default;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid #666;
}

.matecat-modal-content .disabled,
.matecat-modal-content .disabled:hover,
.matecat-modal-content .disabled:active {
  cursor: default;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background: #d6d6d6;
}

.x-popup,
.x-popup2 {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:hover {
    color: colors.$darkBlueTransparent;
  }
}

.x-popup:before,
.x-popup2:before {
  content: '\f057';
}

.matecat-modal-content,
.matecat-modal-overlay-content {
  font-size: 16px;
  font-family: Calibri, Arial, Helvetica, sans-serif;

  .matecat-modal-middle,
  .matecat-modal-bottom,
  .matecat-modal-top {
    padding: 25px;

    .ui.members-list {
      min-height: 45px;

      .ui.divided.list {
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;

        overflow-y: auto;
        max-height: 210px;
        position: relative !important;
        padding: 0;

        .item {
          border-top: none !important;
        }
      }

      ::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #a7a5a5;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }

      ::-webkit-scrollbar-thumb:window-inactive {
        background: #a7a5a5;
      }

      &.team {
        .item {
          padding: 5px 5px 5px 0;

          &:hover {
            .mini.ui.button.right.floated {
              display: inherit;
            }

            .content.pending-msg {
              display: none;
            }
          }

          .image {
            width: 40px !important;
            max-width: 40px;
            height: 40px;
            background-color: colors.$grey2;
            border-radius: 50%;
            vertical-align: middle;
            text-align: center;
            font-size: 17px;
          }

          .content.user {
            font-weight: bold;
            width: 340px;

            &.invited {
              font-weight: 100;
            }
          }

          .content.email-user-invited {
            font-weight: 100;
            font-size: 14px;
            color: colors.$grey1;
          }

          .content.pending-msg {
            font-weight: 100;
            font-size: 14px;
            color: colors.$grey1;
            white-space: nowrap;
          }

          .mini.ui.button.right.floated {
            margin-top: 6px;
            border: 1px solid #797979;
            display: inherit;
            border-radius: 2px;
            font-family: 'Calibri', 'Helvetica Neue', Arial, Helvetica,
              sans-serif;
            font-size: 14px;
            padding: 6px 15px;
            background-color: #f6f6f6;
            white-space: nowrap;

            &:hover {
              box-shadow:
                0 0 0 #e0e0e0,
                0 0 2px rgba(0, 0, 0, 0.12),
                0 2px 4px rgba(0, 0, 0, 0.24) !important;
            }

            &:focus {
              box-shadow: none !important;
            }

            &:active {
              box-shadow: none !important;
            }
          }

          .mini.ui.primary.button {
            border: 1px solid #797979;
            border-radius: 2px;
            font-family: 'Calibri', 'Helvetica Neue', Arial, Helvetica,
              sans-serif;
            font-size: 14px;
            padding: 6px 15px;
            font-family: 'Calibri', 'Helvetica Neue', Arial, Helvetica,
              sans-serif;

            i {
              opacity: 1;
            }
          }

          .ui.primary.button {
            border: 1px solid #797979;
            border-radius: 2px;
            font-family: 'Calibri', 'Helvetica Neue', Arial, Helvetica,
              sans-serif;
          }

          .mini.ui.icon.button {
            width: 30px;
            height: 30px;
            line-height: 20px;
            text-align: center;
            vertical-align: bottom;
            border: 1px solid #797979;
            border-radius: 2px;
          }
        }

        .pending-invitation {
          display: flex;
          align-items: center;

          .email {
            padding-left: 0.5em;
          }

          .mini.ui.button.right.floated {
            margin-top: 0;
          }

          > :last-child {
            display: flex;
            align-items: center;
            margin-left: auto;
          }
        }
      }
    }
  }

  .matecat-modal-text {
    padding: 10px 10px;
    font-size: 17px;
  }

  .matecat-modal-textarea {
    padding: 10px 10px;
    resize: none;

    textarea {
      padding: 10px;
      border: 1px solid grey;
      border-radius: 4px;
    }
  }

  .check-conditions {
    margin-left: 5px;
  }
}

.pull-left {
  float: left;
}

.pull-right {
  float: right !important;
}

.create-team-modal,
.modify-team-modal,
.shortcuts-modal {
  .create-team {
    font-family: 'Calibri', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 11px 22px;
    vertical-align: top;
    font-size: 18px;
    margin-right: 0px;
    border-radius: 2px;

    &.primary.button {
      border-radius: 2px;
    }
  }
}

.modify-team-modal {
}

.ui.fluid.input > input {
  font-family: 'Calibri', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  box-shadow: inset 0 1px 3px #ddd;
  font-size: 15px;
}

.ui.multiple.search.dropdown {
  width: 100%;
  box-shadow: inset 0 1px 3px #ddd;
  font-size: 15px;
}

.btn-cancel.outsource-cancel-date {
  height: 16px;

  &:hover {
    color: #333333;
  }
}

.login-form-container {
  .form-divider {
    margin-bottom: 15px;
  }
}

.form-divider {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  height: 20px;
  line-height: 20px;
  margin-top: 15px;

  .divider-line {
    height: 2px;
    width: 40%;
    background-color: #c5c5c5;
    margin-top: 10px;
    display: block;
    float: left;
  }

  span {
    float: left;
    width: 20%;
    color: #656565;
  }
}

.matecat-modal-content {
  .message-modal {
    padding: 25px 0;
  }

  .matecat-modal-middle {
    padding: 0 25px;

    .ui.primary.button,
    .ui.red.button {
      font-family: 'Calibri', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      border: 1px solid #797979;
      border-radius: 2px;
    }
  }

  .create-team-modal {
    .ui.members-list {
      .ui.divided.list {
        .item:first-child {
        }
      }

      .ui.divided.list.disabled {
        border: none;
        background-color: white;
      }
    }
  }

  .modify-team-modal {
    .matecat-modal-top {
      .ui.fluid.input {
        i {
          display: none;
        }

        &:hover {
          i {
            display: inherit;
          }
        }
      }

      .ui.icon.input > input:focus ~ i.icon {
        opacity: 1;
        display: inherit;
      }
    }

    .ui.members-list {
      //min-height: 180px;
      min-height: initial !important;

      .ui.divided.list {
        height: 210px;

        .item:first-child {
          //border-top: none !important;
        }
      }
    }

    .search-member-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 0;
      border-radius: 4px;
      padding: 6px;
      font-size: 15px;
      color: black;
      transition: 0.6s;

      > svg {
        margin-right: 8px;
        color: colors.$grey6;
      }

      > input {
        width: 100%;
        background: unset;
        outline: none;
        border: none;

        &::placeholder {
          color: colors.$grey6;
        }
      }

      &:hover {
        background-color: white;
      }

      .reset_button {
        display: flex;
        cursor: pointer;
        margin-right: 2px;

        > svg {
          color: colors.$grey6;
        }
      }

      .reset_button--hidden {
        visibility: hidden;
      }

      .reset_button--visible {
        visibility: visible;
      }
    }

    .no-result {
      font-size: 14px;
      color: #666666;
      padding: 7px;
    }
  }

  .change-team-modal {
    height: 305px;

    .matecat-modal-top {
      .move-ribbon {
        background: #e8e8e8;
        padding: 8px 15px;
        border-radius: 4px;

        .project-name {
          font-weight: 600;
        }

        .project-id {
          float: right;
        }
      }
    }

    .button {
      font-size: 18px;
      padding: 11px 22px;
    }

    .dropdown {
      .menu {
        max-height: 85px !important;
      }

      ::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #a7a5a5;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }

      ::-webkit-scrollbar-thumb:window-inactive {
        background: #a7a5a5;
      }

      .default.text {
        color: #000;
      }
    }
  }
}

.dropdown > .menu {
  z-index: 100000000;
}

.shortcuts-modal {
  .matecat-modal-top {
    padding: 15px 25px;
  }

  .matecat-modal-middle {
    padding: 10px 15px;
    max-height: 530px;
    overflow: auto;

    .shortcut-list {
      &:not(:first-of-type) {
        padding-top: 10px;
      }

      h2 {
        padding-left: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee7e8;
      }

      .shortcut-item-list {
        .shortcut-item {
          padding: 7px 0 7px 10px;
          margin: 3px 0;

          &:first-child {
            margin-top: 10px;
          }

          &:hover {
            background: #f1f1f1;
          }

          .shortcut-title,
          .shortcut-keys {
            display: inline-block;
            width: 50%;
          }

          .shortcut-keys {
            .shortcuts {
              text-align: right;
              color: #03bdee;
            }

            .mac,
            .standard {
              .keys {
                content: '';
                font-family: 'Courier New';
                display: inline-block;
                margin-left: 5px;
                margin-right: 5px;
                background: #909798;
                padding: 0 5px;
                line-height: 20px;
                font-size: 12px;
                color: white;
                border-radius: 2px;

                &:first-child {
                  margin-left: 0px !important;
                }

                &.ctrl:after {
                  content: 'Ctrl';
                }

                &.Meta:after {
                  content: 'Cmd';
                }

                &.shift:after {
                  content: 'Shift';
                }

                &.return:after {
                  content: 'Enter';
                }

                &.meta:after {
                  content: 'Cmd';
                }

                &.alt:after {
                  content: 'Alt';
                }

                &.option:after {
                  content: 'Option';
                }

                &.\31 :after {
                  content: '1';
                }

                &.\32 :after {
                  content: '2';
                }

                &.\33 :after {
                  content: '3';
                }

                &.a:after {
                  content: 'A';
                }

                &.c:after {
                  content: 'C';
                }

                &.h:after {
                  content: 'H';
                }

                &.i:after {
                  content: 'I';
                }

                &.l:after {
                  content: 'L';
                }

                &.n:after {
                  content: 'N';
                }

                &.p:after {
                  content: 'P';
                }

                &.z:after {
                  content: 'Z';
                }

                &.f:after {
                  content: 'F';
                }

                &.k:after {
                  content: 'K';
                }

                &.y:after {
                  content: 'Y';
                }

                &.s:after {
                  content: 'S';
                }

                &.t:after {
                  content: 'T';
                }

                &.q:after {
                  content: 'Q';
                }

                &.up:after {
                  content: 'Arrow up';
                }

                &.right:after {
                  content: 'Arrow right';
                }

                &.left:after {
                  content: 'Arrow left';
                }

                &.down:after {
                  content: 'Arrow down';
                }

                &.pagedown:after {
                  content: 'Page Down';
                }

                &.pageup:after {
                  content: 'Page Up';
                }

                &.previous:after {
                  content: '<';
                }

                &.next:after {
                  content: '>';
                }

                &.home:after {
                  content: 'Home';
                }

                &.arrows:after {
                  content: 'Arrows';
                }

                &.arrows-enter:after {
                  content: 'Arrows/Enter';
                }

                &.space:after {
                  content: 'Space';
                }
                &.bracketLeft:after {
                  content: '[';
                }
                &.bracketRight:after {
                  content: ']';
                }
              }
            }
          }
        }
      }
    }
  }

  .matecat-modal-bottom {
    padding: 15px 25px;
  }
}

.copy-source-modal {
  padding: 30px 25px 20px;

  h3 {
    font-size: 20px;
  }

  .buttons-popup-container {
    padding: 25px 0 30px;
    border-bottom: 1px solid #f2f4f7;
    display: flex;
    gap: 20px;
    align-items: center;
    label {
      font-size: 18px;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }

    .btn-cancel,
    .btn-ok {
      padding: 10px 15px;
      margin-left: 5px;
    }

    .btn-cancel {
      margin-right: 16px;
      font-weight: 100;
    }
  }

  .boxed {
    padding: 15px 0 0;
    text-align: right;

    label {
      position: relative;
      top: 1px;
      padding-right: 2px;
    }
  }
}

.matecat-modal-content {
  .boxed {
    padding: 25px 15px 0;
    text-align: left;

    label {
      position: relative;
      top: -2px;
      padding-right: 2px;
    }
  }
}

[data-name='confirmCopyAllSources'] {
  input,
  label {
    display: inline-block;
    vertical-align: middle;
  }

  label {
    margin-left: 5px;
  }

  .text-container-top {
    padding: 20px !important;
    margin-top: 0 !important;
  }

  .popup p {
    margin-bottom: 0;
  }

  .boxed {
    padding-right: 20px;
    padding-bottom: 0 !important;
  }
}

.alert_modal {
  .matecat-modal-body {
    padding: 30px !important;

    p {
      line-height: 25px;
    }
  }
}

.confirm-delete-resource-project-templates {
  ul {
    list-style: disc;
    padding: 10px 20px;
    font-weight: bold;
  }

  .tm-row-delete-remove-from-content {
    > :nth-child(2) {
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }
}
