@use '../../commons/colors';

.settings-panel-table {
  position: relative;
  z-index: 0;

  .settings-panel-button {
    cursor: pointer;
    color: colors.$black;
    background: colors.$grey8;
    border-radius: 2px 0 0 2px;
    padding: 6px 30px;
    border: 1px solid colors.$grey;
    &:hover {
      background-color: colors.$grey7;
    }
  }
  .settings-panel-table-row-empty {
    padding: 20px;
    font-size: 16px;
  }
}

.settings-panel-table-rowHeading {
  display: grid;
  align-items: center;
  background-color: colors.$grey;
  height: 30px;
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.settings-panel-table-rowHeading-column {
  text-align: left;
  padding: 0 10px;
}

.settings-panel-row {
  display: flex;
  flex-direction: column;
  border: 1px solid colors.$grey8;
  border-bottom: unset;
  transition: padding 0.2s ease-out 0.15s;
  .settings-panel-cell-center {
    align-self: center;
    justify-content: center;
    display: flex;
  }
}

.settings-panel-row-content {
  display: grid;
  align-items: center;
  width: 100%;
  min-height: 50px;
  background-color: colors.$grey9;
}

.settings-panel-row-content > *:not(.settings-panel-row-drag-handle) {
  text-align: left;
  padding: 0 10px;

  &.align-center {
    text-align: center;
  }
}

.settings-panel-row-active {
  background-color: colors.$transparentBlue;
}

.settings-panel-row:last-child {
  border-bottom: 1px solid colors.$grey8;
}

.settings-panel-row-drag-handle {
  position: absolute;
  margin-left: 15px;
  margin-top: 8px;
  cursor: move;
  border: 2px dotted #ccc;
  border-top: 0;
  border-bottom: 0;
  width: 2px;
  height: 30px;
}

.settings-panel-row-dragging {
  opacity: 0.5;
}

@keyframes drag-end {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.settings-panel-row-dragend {
  animation-name: drag-end;
  animation-duration: 0.8s;
}

.settings-panel-row-dragover-half-top {
  padding-top: 50px;
}

.settings-panel-row-dragover-half-bottom {
  padding-bottom: 50px;
}

.settings-panel-row-extra-content {
  background-color: colors.$white;
  overflow: hidden;
  min-height: 0px;
  max-height: 0px;
  opacity: 0;
  border-top: 1px solid #e1e1e1;
  box-shadow: inset 0px 2px 5px 0px #e4e4e4;
  transition:
    max-height 0.8s ease-in-out,
    min-height 0.2s ease-in-out,
    opacity 0.2s linear 0.3s;
}

.settings-panel-row-extra-content-expanded {
  visibility: visible;
  min-height: 50px;
  max-height: 400px;
  opacity: 1;
  overflow: auto;
}

.settings-panel-mt-row {
  display: flex;
  gap: 10px;

  > a {
    display: flex;
    align-items: center;
  }
}

.settings-panel-mt-row-description {
  padding: 5px 10px !important;
}
