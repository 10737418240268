@use "../../commons/colors";
.user-disconnect-box{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 7;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(6px);
  .user-disconnect-box_content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 8px;
    width: 100%;
    background-color: colors.$white;
    max-width: 500px;
    padding: 30px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      gap: 20px;
      font-size: 16px;
      h2 {
        font-size: 22px;
        margin: 0;
      }
    }
  }
}