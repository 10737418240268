@use '../../commons/colors';

.segmented-control__wrapper > label {
  display: block;
  margin-bottom: 8px;
  cursor: default;
}

.segmented-control {
  position: relative;
  display: flex;
  user-select: none;
  border-radius: 4px;
  background-color: colors.$grey5;
}

.segmented-control__label {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 16px;
  line-height: 48px;
  color: colors.$grey1;
  transition: color 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;

  .segmented-control--compact & {
    line-height: 40px;
  }

  .segmented-control--single & {
    cursor: default;
  }

  &:hover {
    color: colors.$grey6;
  }
}

.segmented-control input {
  display: none;
}
.segmented-control input:checked + .segmented-control__label {
  color: white;
}

.segmented-control__cursor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 4px;
  transition: transform 0.2s ease-out;
}
.segmented-control__cursor:before {
  content: '';
  display: block;
  height: 100%;
  border-radius: 4px;
  background-color: colors.$translatedBlue;
}
