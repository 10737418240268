@use '../../commons/colors';

button.button-component-container,
a.button-component-container {
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  background-color: transparent;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  width: auto;
  font-weight: 500;
  transition-property: color, background-color, box-shadow, opacity;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    flex-shrink: 0;
    pointer-events: none;
  }



  // Mode modifiers
  &.basic {
    background-color: var(--btnBgColor);
    color: var(--btnTextColor);

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: var(--btnBgColorAlt);
      color: var(--btnTextColor);
    }

    &:disabled {
      background-color: var(--btnBgColorSemitransAlt);
      color: var(--btnTextColorDisabled);

      svg {
        color: var(--btnTextColorDisabled);
      }
    }
  }
  &.outline {
    box-shadow: inset 0 0 0 1px var(--btnBorderColor);
    color: var(--btnAltTextColor);

    &:not(:disabled):global(.button--active) {
      box-shadow: inset 0 0 0 1px var(--btnBorderColorActive);
      background-color: var(--btnBgColorSemitransAlt);
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      box-shadow: inset 0 0 0 1px var(--btnBorderColorHover);
      background-color: var(--btnBgColorSemitrans);
      color: var(--btnAltTextColorHover);
    }

    &:disabled {
      box-shadow: inset 0 0 0 1px var(--btnBorderColorDisabled);
      color: var(--btnAltTextColorDisabled);

      svg {
        color: var(--btnAltTextColorDisabled);
      }
    }
  }
  &.ghost {
    color: var(--btnAltTextColor);

    &:not(:disabled):global(.button--active),
    &:not(:disabled):global(.button--active):hover {
      background-color: var(--btnBgColorSemitransAlt);
      color: var(--btnAltTextColor);
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: var(--btnBgColorSemitrans);
      color: var(--btnAltTextColor);
    }

    &:disabled {
      color: var(--btnAltTextColorDisabled);

      svg {
        color: var(--btnAltTextColorDisabled);
      }
    }
  }
  &.link {
    color: var(--btnAltTextColor);

    &:not(:disabled):global(.button--active) {
      color: colors.$grey8;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: colors.$black;
    }

    &:disabled {
      color: var(--btnAltTextColorDisabled);

      svg {
        color: var(--btnAltTextColorDisabled);
      }
    }
  }

  // Size modifiers
  &.small {
    height: 28px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
  }
  &.standard {
    height: 40px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
  }
  &.medium {
    height: 40px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
  }
  &.big {
    height: 48px;
    padding: 0 24px;
    line-height: 48px;
    font-size: 18px;
    font-weight: bold;
  }
  &.iconSmall {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
  &.iconStandard {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
  &.iconBig {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
  }
  &.fullWidth {
    width: 100%;
  }
  &.linkSmall {
    height: auto;
    font-size: 14px;
  }
  &.linkMedium {
    height: auto;
    font-size: 16px;
  }
  &.linkBig {
    height: auto;
    font-size: 18px;
  }

  // Waiting state
  .hiddenContent {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    white-space: nowrap;
    visibility: hidden;
  }
  &.waiting .spinner {
    position: absolute;
  }
}

// Type modifiers
.default {
  --btnTextColor: #{colors.$grey6};
  --btnTextColorDisabled: #{colors.$grey8};
  --btnAltTextColor: #{colors.$grey6};
  --btnAltTextColorHover: #{colors.$grey};
  --btnAltTextColorDisabled: #{rgba(colors.$white, 0.12)};

  --btnBorderColor: #{colors.$grey8};
  --btnBorderColorHover: #{colors.$grey6};
  --btnBorderColorActive: #{colors.$grey6};
  --btnBorderColorDisabled: #{rgba(colors.$white, 0.12)};

  --btnBgColor: #{colors.$white};
  --btnBgColorAlt: #{colors.$grey9};
  --btnBgColorSemitrans: #{rgba(colors.$white, 0.5)};
  --btnBgColorSemitransAlt: #{rgba(colors.$white, 0.5)};
}
.primary {
  --btnTextColor: #{colors.$white};
  --btnTextColorDisabled: #{colors.$white};
  --btnAltTextColor: #{colors.$translatedBlue};
  --btnAltTextColorHover: #{colors.$translatedBlueHover};
  --btnAltTextColorDisabled: #{rgba(colors.$translatedBlue, 0.24)};

  --btnBorderColor: #{rgba(colors.$translatedBlue, 0.24)};
  --btnBorderColorHover: #{colors.$translatedBlueHover};
  --btnBorderColorActive: #{colors.$translatedBlue};
  --btnBorderColorDisabled: #{rgba(colors.$translatedBlue, 0.24)};

  --btnBgColor: #{colors.$translatedBlue};
  --btnBgColorAlt: #{colors.$translatedBlueHover};
  --btnBgColorSemitrans: #{rgba(colors.$translatedBlue, 0.12)};
  --btnBgColorSemitransAlt: #{rgba(colors.$translatedBlue, 0.24)};
}
.info {
  --btnTextColor: #{colors.$white};
  --btnTextColorDisabled: #{colors.$white};
  --btnAltTextColor: #{colors.$linkBlue};
  --btnAltTextColorHover: #{colors.$linkBlueHover};
  --btnAltTextColorDisabled: #{rgba(colors.$linkBlue, 0.24)};

  --btnBorderColor: #{rgba(colors.$linkBlue, 0.24)};
  --btnBorderColorHover: #{colors.$linkBlue};
  --btnBorderColorActive: #{colors.$linkBlue};
  --btnBorderColorDisabled: #{rgba(colors.$linkBlue, 0.24)};

  --btnBgColor: #{colors.$linkBlue};
  --btnBgColorAlt: #{colors.$linkBlueHover};
  --btnBgColorSemitrans: #{rgba(colors.$linkBlue, 0.12)};
  --btnBgColorSemitransAlt: #{rgba(colors.$linkBlue, 0.24)};
}
.success {
  --btnTextColor: #{colors.$white};
  --btnTextColorDisabled: #{colors.$white};
  --btnAltTextColor: #{colors.$greenDefault};
  --btnAltTextColorHover: #{colors.$greenDefaultHover};
  --btnAltTextColorDisabled: #{rgba(colors.$greenDefault, 0.24)};

  --btnBorderColor: #{rgba(colors.$greenDefault, 0.24)};
  --btnBorderColorHover: #{colors.$greenDefault};
  --btnBorderColorActive: #{colors.$greenDefault};
  --btnBorderColorDisabled: #{rgba(colors.$greenDefault, 0.24)};

  --btnBgColor: #{colors.$greenDefault};
  --btnBgColorAlt: #{colors.$greenDefaultHover};
  --btnBgColorSemitrans: #{rgba(colors.$greenDefault, 0.12)};
  --btnBgColorSemitransAlt: #{rgba(colors.$greenDefault, 0.24)};
}
.warning {
  --btnTextColor: #{colors.$white};
  --btnTextColorDisabled: #{colors.$white};
  --btnAltTextColor: #{colors.$warning};
  --btnAltTextColorHover: #{colors.$warningHover};
  --btnAltTextColorDisabled: #{rgba(colors.$warning, 0.24)};

  --btnBorderColor: #{rgba(colors.$warning, 0.24)};
  --btnBorderColorHover: #{colors.$warning};
  --btnBorderColorActive: #{colors.$warning};
  --btnBorderColorDisabled: #{rgba(colors.$warning, 0.24)};

  --btnBgColor: #{colors.$warning};
  --btnBgColorAlt: #{colors.$warningHover};
  --btnBgColorSemitrans: #{rgba(colors.$warning, 0.12)};
  --btnBgColorSemitransAlt: #{rgba(colors.$warning, 0.24)};
}
.critical {
  --btnTextColor: #{colors.$white};
  --btnTextColorDisabled: #{colors.$white};
  --btnAltTextColor: #{colors.$redDefault};
  --btnAltTextColorHover: #{colors.$redDefaultHover};
  --btnAltTextColorDisabled: #{rgba(colors.$redDefault, 0.24)};

  --btnBorderColor: #{rgba(colors.$redDefault, 0.24)};
  --btnBorderColorHover: #{colors.$redDefault};
  --btnBorderColorActive: #{colors.$redDefault};
  --btnBorderColorDisabled: #{rgba(colors.$redDefault, 0.24)};

  --btnBgColor: #{colors.$redDefault};
  --btnBgColorAlt: #{colors.$redDefaultHover};
  --btnBgColorSemitrans: #{rgba(colors.$redDefault, 0.12)};
  --btnBgColorSemitransAlt: #{rgba(colors.$redDefault, 0.24)};
}
