@use "../../commons/colors";
.input-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: default;
    user-select: none;
    &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 1px;
        border-radius: 2px;
        width: 14px;
        height: 14px;
        background-color: rgba(colors.$white, 0.72);
    }
    &.checked::before {
        background-color: colors.$white;
    }

    &.isDisabled {
        cursor: not-allowed;
    }

    > input[type='checkbox'] {
        display: none;
    }

    > svg {
        position: relative;
        z-index: 1;
        flex-shrink: 0;
        color: colors.$grey6;
    }

    input[type='checkbox']:not(:disabled):checked,
    input[type='checkbox']:not(:disabled):indeterminate {
        & + svg {
            color: colors.$translatedBlue;
        }
    }

    input[type='checkbox']:disabled {
        & + svg {
            opacity: 0.4;
            color: colors.$grey7;
        }

        & ~ span {
            color: colors.$grey7 ;
        }
    }

    > span {
        font-size: 12px;
        color: colors.$black;
    }
}

.errorMessage {
    margin-top: 8px;
    font-size: 12px;
    color: colors.$redDefault;
}
