@use "../../commons/colors";
.machine-translation-tab {
  .add-mt-button,
  .add-mt-container {
    h2 {
      align-self: flex-start;
      line-height: 40px;
      font-size: 20px;
    }
  }

  li.dropdown__option {
    height: 36px;
  }

  .confirm-button {
    height: 26px;
    font-size: 14px !important;
    min-width: unset;
    gap: 5px !important;
    border-radius: unset;
    padding: 0 8px;
  }

  .close-button {
    width: 30px;
    height: 26px;
    display: flex;
    justify-content: center;
    border-radius: unset;
    padding: 0;
  }
}
.add-mt-button {
  display: flex;
  justify-content: flex-end;
}
.add-mt-container {
  display: flex;
  flex-direction: column;
  .add-mt-provider {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .add-provider-container {
    display: flex;
    padding-top: 15px;
    align-items: flex-start;
    .add-provider-fields {
      width: 50%;
      text-align: left;
      padding: 15px 15px 0 0;
      .provider-data {
        //&.provider-data-lara {
        //  .provider-field:last-child {
        //    margin-top: -12px
        //  }
        //}
        display: flex;
        flex-direction: column;
        .button {
          align-self: flex-end;
          margin-top: 20px;
        }
      }
      .provider-field {
        display: flex;
        flex-direction: column;
        gap: 4px;
        min-width: 0;
        width: 100%;
        font-size: 16px;
        &:not(:first-child) {
          margin-top: 20px;
        }
        &.checkbox {
          flex-direction: row;
          margin: 6px 0;
          align-items: center;
          &.first {
            margin-top: 20px;
          }
          input {
            margin-right: 10px;
          }
        }
        span {
          font-size: 14px;
        }
        input[type='text'] {
          padding: 3px 5px 3px 9px;
          font-size: 16px !important;
          -moz-border-radius: 2px;
          border-radius: 2px;
          border: 1px solid rgba(34, 36, 38, 0.15);
          height: 37px;
          box-shadow: inset 0 1px 3px #ddd;
          &:hover {
            border-color: rgba(34, 36, 38, 0.35);
            box-shadow: none;
          }
        }
        .field-error {
          color: colors.$redDefault;
          font-size: 12px;
        }
        .mt-error {
          color: colors.$redDefault;
        }
      }
    }
    .add-provider-message {
      width: 50%;
      text-align: left;
      padding: 15px;
      background: colors.$grey5;
      display: flex;
      flex-direction: column;
      .button {
        margin-top: 20px;
      }
      ul {
        list-style: circle;
        padding-left: 25px;
        padding-top: 6px;
      }
      p {
        line-height: 26px;
      }
    }
  }

  .provider-field-row {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
.machine-translation-tab-table-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 10px;

  > span:first-of-type {
    flex-grow: 1;
    text-align: left;
  }
}
.machine-translation-tab {
  .settings-panel-table-rowHeading,
  .settings-panel-row-content {
    min-height: 40px;
    grid-template-columns: 15% minmax(0, 1fr) 15% 15%;
  }
  .settings-panel-row {
    border-top: unset;
  }
  .settings-panel-row-extra-content {
    border: none;
  }

  .settings-panel-row-content {
    background-color: colors.$white;
  }

  .settings-panel-row-active {
    background-color: colors.$transparentBlue;
  }
  .settings-panel-table-rowHeading-column:nth-child(3),
  .settings-panel-table-rowHeading-column:nth-child(4) {
    text-align: center;
  }

  .select-with-label__wrapper {
    .select {
      font-size: 16px;
      padding: 9px 46px 9px 12px;
      border-radius: 2px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      box-shadow: inset 0 1px 3px #ddd;
      color: black;
      &:hover {
        border-color: rgba(34, 36, 38, 0.35);
        box-shadow: none;
      }
    }

    .select__dropdown-wrapper {
      min-width: 280px;
      z-index: 1;
      margin-top: 1px;
    }
  }

  //Dropdown overrides
  .add-mt-provider .select-with-icon__wrapper {
    height: 40px;
  }
  .add-provider-container .select-with-icon__wrapper {
    height: 37px;
  }

  .add-mt-provider .select-with-label__wrapper {
    margin-right: 30px;
  }

  .provider-license-label-with-icon {
    display: flex;
    gap: 5px;
  }

  .provider-data-lara {
    .button {
      margin-top: 62px !important;
    }

    .provider-field {
      gap: 15px !important;
    }
  }
}
.machine-translation-tab {
  .settings-panel-table-rowHeading,
  .settings-panel-row-content {
    min-height: 40px;
    grid-template-columns: 15% minmax(0, 1fr) 15% 15%;
  }
  .settings-panel-table-rowHeading-column:nth-child(n + 3) {
    text-align: center;
  }

  .settings-panel-table {
    .select {
      padding: 2px 8px;
      height: 24px;
    }
  }

  .active-table-ModernMT,
  .active-table-DeepL {
    .settings-panel-row-extra-content {
      transition: none;
      min-height: unset;
      max-height: none;
    }
  }

  .active-table-DeepL {
    z-index: 1;

    .settings-panel-table-rowHeading,
    .settings-panel-row-content {
      min-height: 40px;
      grid-template-columns: 15% minmax(0, 1fr) 12% 15% 15%;
    }
  }

  .select__dropdown--is-reversed {
    top: 100% !important;
  }

  .select,
  .dropdown__option--is-active-option,
  .dropdown__option.dropdown__option--is-active-option:hover {
    color: black;
  }
}

.mt-glossary {
  display: flex;
  flex-direction: column;
  background-color: colors.$white;

  .expand-button {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eaebee;

    > button {
      background: unset;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }

      &.rotate {
        > svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .settings-panel-table {
    .settings-panel-table-rowHeading {
      background-color: unset;
      color: black;

      & > :first-child {
        text-align: center;
      }
    }
    .settings-panel-row-content {
      grid-template-columns: 15% minmax(0, 1fr) 22% 5%;
    }
    .row-content-create {
      grid-template-columns: 15% minmax(0, 1fr) 22% 5%;
    }
    .settings-panel-row {
      border: none;

      .row-content-create-glossary {
        grid-template-columns: 100%;

        > form {
          padding: 0 !important;
        }
      }
      .row-content-create-glossary-waiting {
        opacity: 0.6;
      }
    }
    .settings-panel-row-active {
      background-color: unset;
    }
  }

  .main-buttons-container {
    position: absolute;
    width: 100%;
    padding: 0 10px 0 230px;
    margin-top: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.glossary-row-name {
  display: flex;
  gap: 10px;

  .editing-buttons {
    display: flex;
    gap: 10px;
  }

  .tooltip-input-name {
    min-width: 70%;
  }
}

.glossary-row-name-input {
  width: 100%;
  padding: 4px;
  background-color: unset;
  border: 1px solid colors.$grey8;
  border-radius: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &.active,
  &:focus,
  &:hover {
    background-color: white;
  }

  &.error {
    border: solid 1px colors.$redDefault;
  }
}

.glossary-deepl-row-name-input {
  &:hover {
    background-color: unset;
  }
}

.glossary-row-import-button {
  display: flex;
  justify-content: end;
  gap: 10px;

  input[type='file'] {
    display: none;

    &:disabled + label {
      opacity: 0.5;
      cursor: default;
    }
  }

  .grey-button {
    &.error {
      border: solid 1px colors.$redDefault;
    }
  }

  .filename {
    display: flex;
    align-items: center;

    > label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 220px;
    }
  }
}

.glossary-row-name-create-input {
  width: auto;
  min-width: 70%;
}

.glossary-row-confirm-button {
  display: flex;
  justify-content: end;
}

.glossary-row-delete {
  padding: 0 !important;
}

.grey-button {
  display: flex;
  align-items: center;
  height: 26px;
  gap: 8px;
  border-radius: 2px;
  font-size: 16px;
  color: #000;
  background: #eaebee;
  padding: 0 8px;
  text-align: center;
  border: 1px solid #9e9e9e;
  cursor: pointer;

  &:hover {
    background-color: colors.$grey5;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.mt-glossary-case-sensitive {
  display: flex;
  gap: 6px;
}

.spinner {
  position: absolute;
  background: url(/public/img/loading.gif) 48% top no-repeat !important;
  background-size: 26px 26px !important;
  width: 26px;
  height: 26px;
  margin-left: 10px;
}

.mt-glossary-table {
  padding-top: 15px;

  .settings-panel-table-rows-container {
    max-height: 400px;
    overflow-y: auto;
  }
}

.empty-list-mode {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  > p {
    font-size: 14px;
    margin: 0 0 10px 0;
  }
}

.loading-list-mode {
  padding: 10px;
}

.create-glossary-button {
  gap: unset;
  padding: 2px 8px 2px 4px;
}

.create-glossary-button-disabled {
  visibility: hidden;
}
