@use '../../commons/colors';
.login-component {
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center;
  padding: 48px 64px 48px 64px;
  gap: 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    margin:0;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
  }

  .login-divider {
    color: grey;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    div {
      flex-grow: 1;
      height: 1px;
      background-color: grey;
    }

    span {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .login-social-buttons {
    display: flex;
    gap: 16px;
    justify-content: center;
  }

  .footer-links-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: 16px;
    color: colors.$grey7;

    > :first-child {
      display: flex;
    }
  }
}
