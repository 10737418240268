@use "../../commons/colors";
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin');

/******** Overwrite Semantic CSS  *********/

.ui.basic.buttons .active.button,
.ui.basic.active.button {
  box-shadow: rgba(34, 36, 38, 0.35);
}
.ui.basic.buttons .active.button {
  box-shadow: rgba(34, 36, 38, 0.35) inset;
}

/*--- Blue ---*/

.ui.blue.buttons .button,
.ui.blue.button {
  background-color: colors.$translatedBlue;
}
.ui.blue.buttons .button:hover,
.ui.blue.button:hover {
  background-color: colors.$translatedBlueHover;
}
/* Basic */

.ui.basic.blue.buttons .button,
.ui.basic.blue.button {
  box-shadow: 0px 0px 0px 1px colors.$translatedBlue inset !important;
  color: colors.$translatedBlue !important;
}

.ui.basic.blue.buttons .button:hover,
.ui.basic.blue.button:hover {
  color: colors.$translatedBlueHover !important;
}

.ui.basic.blue.buttons .button:focus,
.ui.basic.blue.button:focus {
  color: colors.$translatedBlueHover !important;
}

/*--- Green ---*/

.ui.green.buttons .button,
.ui.green.button {
  background-color: colors.$greenDefault;
  transition: 0.3s ease;
}

.ui.green.buttons .button:hover,
.ui.green.button:hover {
  background-color: colors.$greenDefaultHover;
  //box-shadow: 0 0 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24) !important;
}

.ui.green.buttons .button:focus,
.ui.green.button:focus {
  background-color: colors.$greenDefaultHover;
  box-shadow: none !important;
}

.ui.green.buttons .button:active,
.ui.green.button:active {
  background-color: colors.$greenDefaultHover;
  box-shadow: none !important;
  transition: none !important;
}

.ui.green.buttons .active.button,
.ui.green.buttons .active.button:active,
.ui.green.active.button,
.ui.green.button .active.button:active {
  box-shadow: none !important;
  transition: none !important;
}
/*--- Red ---*/

.ui.red.buttons .button,
.ui.red.button {
  background-color: colors.$redDefault;
}

.ui.red.buttons .button:hover,
.ui.red.button:hover {
  background-color: colors.$redDefaultHover;
  //box-shadow: 0 0 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24) !important;
}

.ui.red.buttons .button:focus,
.ui.red.button:focus {
  background-color: colors.$redDefaultHover;
  box-shadow: none !important;
}

.ui.red.buttons .button:active,
.ui.red.button:active {
  background-color: colors.$redDefaultHover;
  box-shadow: none !important;
}

.ui.red.buttons .active.button,
.ui.red.buttons .active.button:active,
.ui.red.active.button,
.ui.red.button .active.button:active {
  background-color: colors.$redDefaultHover;
  box-shadow: none !important;
}

/*-------------------
       Primary
--------------------*/

/*--- Standard ---*/

.ui.buttons .button,
.ui.button {
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  align-items: center;
}
.ui.primary.buttons .button,
.ui.primary.button {
  background-color: colors.$translatedBlue;
  transition: 0.3s ease;
  align-items: center;
}

.ui.primary.buttons .button:hover,
.ui.primary.button:hover {
  background-color: colors.$translatedBlueHover;
  //box-shadow: 0 0 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24) !important;
}

.ui.primary.buttons .button:focus,
.ui.primary.button:focus {
  background-color: colors.$translatedBlueActive;
  box-shadow: none !important;
}

.ui.primary.buttons .button:active,
.ui.primary.button:active {
  background-color: colors.$translatedBlueActive;
  box-shadow: none !important;
  transition: none;
}

.ui.primary.buttons .active.button,
.ui.primary.buttons .active.button:active,
.ui.primary.active.button,
.ui.primary.button .active.button:active {
  transition: none;
}

/* Basic */

.ui.basic.primary.buttons .button,
.ui.basic.primary.button {
  box-shadow: 0px 0px 0px 1px colors.$translatedBlue inset !important;
  color: colors.$translatedBlue !important;
}

.ui.basic.primary.buttons .button:hover,
.ui.basic.primary.button:hover {
  color: colors.$translatedBlueHover !important;
}

.ui.basic.primary.buttons .button:focus,
.ui.basic.primary.button:focus {
  color: colors.$translatedBlueActive !important;
}

/*---------------
    Positive
----------------*/

/* Standard */

.ui.positive.buttons .button:hover,
.ui.positive.button:hover {
  background-color: #85b742;
}

/*---------------
     Negative
----------------*/

/* Standard */

.ui.negative.buttons .button,
.ui.negative.button {
  background-color: #f26522;
}

.ui.negative.buttons .button:hover,
.ui.negative.button:hover {
  background-color: #d1581c;
}
/**********/
i.flag.mm:before,
i.flag.myanmar:before,
i.flag.burma:before {
  background-position: -36px -1717px;
}

/* Blue */

i.blue.icon {
  color: colors.$translatedBlue !important;
}

i.inverted.blue.icon {
  color: #54c8ff !important;
}

i.inverted.bordered.blue.icon,
i.inverted.circular.blue.icon {
  background-color: colors.$translatedBlue !important;
  color: #ffffff !important;
}

/*--- Blue ---*/

.ui.blue.labels .label,
.ui.blue.label {
  background-color: colors.$translatedBlue !important;
  border-color: colors.$translatedBlue !important;
}

/* Link */

.ui.blue.labels .label:hover,
a.ui.blue.label:hover {
  background-color: colors.$translatedBlueHover !important;
  border-color: colors.$translatedBlueHover !important;
}

/* Ribbon */

/* Basic */

.ui.basic.blue.label {
  color: colors.$translatedBlue !important;
  border-color: colors.$translatedBlue !important;
}

.ui.basic.blue.labels a.label:hover,
a.ui.basic.blue.label:hover {
  color: colors.$translatedBlueHover !important;
  border-color: colors.$translatedBlueHover !important;
}

/* Colors */

/* Blue */

.ui.blue.segment:not(.inverted) {
  border-top: 2px solid colors.$translatedBlue;
}

.ui.inverted.blue.segment {
  background-color: colors.$translatedBlue !important;
}

/*----------------------
         Colored
-----------------------*/
/* Blue */
.ui.grid > .blue.row,
.ui.grid > .blue.column,
.ui.grid > .row > .blue.column {
  background-color: colors.$translatedBlue !important;
}

/*--------------
     Colors
---------------*/

/*--- Standard Colors  ---*/
.ui.menu .blue.active.item,
.ui.blue.menu .active.item {
  border-color: colors.$translatedBlue !important;
  color: colors.$translatedBlue !important;
}
/*--------------
    Inverted
---------------*/

/* Red */

.ui.inverted.menu .red.active.item,
.ui.inverted.red.menu {
  background-color: #f26522;
}
/* Blue */

.ui.inverted.menu .blue.active.item,
.ui.inverted.blue.menu {
  background-color: colors.$translatedBlue;
}

/* Colors Message */

.ui.blue.message {
  color: colors.$translatedBlue;
  box-shadow: 0px 0px 0px 1px colors.$translatedBlue inset,
    0px 0px 0px 0px rgba(0, 0, 0, 0);
}

/*--------------
   Single Line
---------------*/
/*-------------------
       Colors
--------------------*/
/* Blue */

.ui.blue.table {
  border-top: 0.2em solid colors.$translatedBlue;
}

.ui.inverted.blue.table {
  background-color: colors.$translatedBlue !important;
}

/* Blue */

.ui.blue.cards > .card,
.ui.cards > .blue.card,
.ui.blue.card {
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px colors.$translatedBlue,
    0px 1px 3px 0px #d4d4d5;
}

.ui.blue.statistics .statistic > .value,
.ui.statistics .blue.statistic > .value,
.ui.blue.statistic > .value {
  color: colors.$translatedBlue;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: colors.$translatedBlue !important;
}

.ui.scrolling.dropdown .menu .item.item.item,
.ui.dropdown .scrolling.menu > .item.item.item {
  padding-right: calc(1.14285714rem + 17px) !important;
}

/*--------------
     Colors
---------------*/

/* Red */

.ui.red.progress .bar {
  background-color: #f26522;
}
/* Blue */

.ui.blue.progress .bar {
  background-color: colors.$translatedBlue;
}

/********************************************/

.ui.popup {
  overflow: initial !important;
  z-index: 20000000000000;
}

.ui.grid.border-box * {
  box-sizing: border-box;
}


/*     ICONS            */
//Manage
.project {
  .ui.icon.buttons .button, .ui.icon.button:not(.animated):not(.compact):not(.labeled) {
    padding: 0;
  }
}
i.icon {
  font-family: "Dropdown";
}

/*   Dropdown      */
.ui.dropdown {
  font-size: unset;
}

.ui.dropdown .menu {
  font-family: "Calibri", "Helvetica Neue", Arial, Helvetica, sans-serif;
  & > .item {
    font-size: 14px;
    color: colors.$black !important;
    min-height: unset;
  }
}
.ui.dropdown .menu > .item a {
  color: colors.$black !important;
}

.ui.header {
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.ui.attached.button {
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) !important;
}
.ui.tiny.buttons .button, .ui.tiny.button {
  font-size: 12px !important;
}

//Old semantic style
button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
