@use 'semantic';
@use '../../commons/colors';
@use 'semantic_overrides';

@font-face {
  font-family: 'Icons';
  src: url('/public/css/fonts/icomoon.eot');
  src: url('/public/css/fonts/icomoon.eot') format('embedded-opentype'),
    url('/public/css/fonts/icomoon.ttf') format('truetype'),
    url('/public/css/fonts/icomoon.woff') format('woff'),
    url('/public/css/fonts/icomoon.svg') format('svg');
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}

a {
  color: colors.$linkBlue;
  &:hover {
    color: colors.$linkBlueHover;
  }
  &:active {
    color: colors.$linkBlueActive;
  }
}
