#powerTip {
  cursor: default;
  background-color: #fff;
  border-radius: 6px;
  color: #000;
  display: none;
  padding: 8px !important;
  position: absolute;
  white-space: nowrap;
  z-index: 2147483647;
  border: 1px solid #d4d4d5;
  border-radius: 0.28571429rem;
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12),
    0px 2px 10px 0px rgba(34, 36, 38, 0.15);
}

#powerTip:before {
  position: absolute;
  content: '';
  width: 0.71428571em;
  height: 0.71428571em;
  background: #ffffff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
  box-shadow: -1px -1px 0px 0px #d4d4d5;
  top: -4px;
}

#powerTip.n:before,
#powerTip.s:before {
  left: 50%;
  margin-left: -5px;
  top: -5px;
}

#powerTip.s:before {
  bottom: -10px;
}
#powerTip.w:before {
  left: -5px;
  transform: rotate(-45deg);
}
#powerTip.n:before {
  bottom: -6px !important;
  top: initial;
  transform: rotate(225deg);
}
#powerTip.e:before {
  right: -5px;
  transform: rotate(135deg);
}

#powerTip.ne-alt:before,
#powerTip.se-alt:before,
#powerTip.ne:before,
#powerTip.se:before {
  left: 10px;
}
#powerTip.nw-alt:before,
#powerTip.sw-alt:before,
#powerTip.nw:before,
#powerTip.sw:before {
  right: 10px;
}
#powerTip.ne-alt:before,
#powerTip.nw-alt:before,
#powerTip.ne:before,
#powerTip.nw:before {
  bottom: -5px;
  transform: rotate(225deg);
  top: initial;
}
#powerTip.se-alt:before,
#powerTip.sw-alt:before,
#powerTip.se:before,
#powerTip.sw:before {
  top: -5px;
  transform: rotate(45deg);
}

/*****  Tips Tm options panel ************/
.powerTip-options-tm {
  text-align: left;
  line-height: 18px;
  font-size: 15px;
  padding: 10px;
}

.powerTip-options-tm ul {
  list-style-type: initial !important;
  margin: 8px 15px 4px 27px !important;
  -moz-column-count: 4;
  -moz-column-gap: 35px;
  -webkit-column-count: 4;
  -webkit-column-gap: 35px;
  column-count: 5;
  column-gap: 35px;
}

.powerTip-options-tm-title {
  font-size: 17px;
  margin-bottom: 5px;
}
