$black: #000;
$grey: #6f6f6f;
$white: white;

$grey1: #788190;
$grey2: #aebdcd;
$grey3: #d9e0e8;
$grey4: #eaebee;
$grey5: #f5f6f7;
$grey6: #666666;
$grey7: #9e9e9e;
$grey8: #d7d8db;
$grey9: #f3f3f3;

$darkBlue: #002b5a;
$darkBlueHover: #00254f;
$darkBlueTransparent: #d9e0e8;

$linkBlue: #4184c4;
$linkBlueHover: #3174b4;
$linkBlueActive: #3174b4;
$linkBlueTransparent: #86aacd;

$translatedBlue: #0099cc;
$translatedBlueHover: #0889b3;
$translatedBlueActive: #0889b3;
$translatedBlueTransparent: #63c3e3;
$transparentBlue: #e4f2fb;

$approvedGreen: #2fb177;
$approvedGreenHover: #1c9f64;
$approvedGreenTransparent: #80d5af;

$approved2Green: #9352c1;
$approved2GreenHover: #7a3ca6;
$approved2GreenTransparent: #b58dd2;

$rebuttedRed: #ff8734;
$rebuttedRedHover: #e9511f;
$rebuttedRedTransparent: #ffaa8e;

$greenDefault: #1fbd1f;
$greenDefaultHover: #1ba61b;
$greenDefaultTransparent: #7cc576;
$greenDefaultTransparent2: #d1e0d1;

$redDefault: #e02020;
$redDefaultHover: #d31d1d;
$redDefaultTransparent: #ffc8ca;

$orangeDefault: #ffcc01;
$orangeDefaultHover: #efbf00;
$orangeDefaultTransparent: #fee47a;
$orangeDefaultTransparent2: #fffed8;

$warning: #f2711c;
$warningHover: #df681a;