@use '../../commons/colors';

.file-import-tab {
  &.settings-panel-contentwrapper-tab-background {
    padding: 10px 20px 20px;
  }

  p {
    display: block;
    text-align: left;
    color: #666666;
    font-size: 16px;
    line-height: 24px;
  }

  .settings-panel-subtemplates {
    position: relative;
    background-color: transparent;
  }
}

.file-import-tab-header {
  position: sticky;
  top: 0;
  z-index: 4;
  width: 100%;
  background-color: colors.$grey5;

  &:has(.select__dropdown-wrapper) {
    z-index: 5;
  }
}

/* Filters params */
.filters-params-accordion-group,
.xliff-settings-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 8px;
}

.filters-params-accordion,
.xliff-settings-container {
  .accordion-component-title {
    font-size: 18px !important;
    font-weight: bold;
  }
}

.filters-params-accordion-content {
  padding: 10px 0;
}

.filters-params-option {
  display: flex;
  gap: 40px;
  align-items: center;
  padding: 15px 0;
  border: unset;

  > :first-child {
    width: 55%;
  }

  > :last-child {
    width: 45%;
  }

  .custom-segmented-control {
    .segmented-control {
      background-color: colors.$grey8;
    }
    .segmented-control__label {
      line-height: 28px;
    }
    .segmented-control__cursor {
      padding: 2px;
    }
    .segmented-control__cursor:before {
      background-color: white;
    }
    .segmented-control input:checked + .segmented-control__label {
      color: black;
    }
  }

  .container-segmented-control {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.xliff-settings-container {
  .accordion-component {
    overflow: visible;

    .accordion-component-content {
      overflow: visible;
    }
  }

  .select__dropdown-wrapper {
    z-index: 5;
  }

  .select {
    color: colors.$black;
    border-color: colors.$grey8;
  }

  .select-with-label__wrapper .select {
    padding: 9px 46px 9px 12px;
  }

  .select--is-disabled {
    background-color: colors.$grey9;
  }

  .unsaved {
    color: colors.$translatedBlue;
  }
}

.xliff-settings-content {
  display: flex;
  gap: 30px;
  padding: 25px 5px;
  flex-direction: column;

  .button-add-rule {
    width: 120px;
  }
}

.xliff-settings-table {
  display: grid;
  grid-template-columns: 4% repeat(3, minmax(0, 1fr)) 4%;
  row-gap: 10px;
  column-gap: 15px;
  align-items: center;
}

.xliff-settings-column-name {
  font-size: 14px;
  font-weight: bold;
}

.xliff-settings-column-name-state {
  grid-column: 2;
}

.xliff-settings-column-name-editor {
  grid-column: 4 / 6;
}

.file-import-options-box {
  float: left;
  width: 100%;
  padding: 20px !important;

  //**** SELECT ****//
  .select-with-label__wrapper {
    .select {
      font-size: 16px;
      padding: 9px 46px 9px 12px;
      border-radius: 2px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      box-shadow: inset 0 1px 3px #ddd;

      &:hover {
        border-color: rgba(34, 36, 38, 0.35);
        box-shadow: none;
      }
    }

    .select--is-focused,
    .select--is-focused:hover {
      border: solid 1px #96c8da;
    }

    .select--is-disabled {
      background-color: #f3f3f3;
    }

    .custom-dropdown {
      margin-top: 0;
      padding-top: 12px;
      border-radius: unset;
      background-color: white;

      &::before {
        display: none;
      }

      .dropdown__search-bar {
        height: 34px;
        margin: 0 12px 12px;
        border-radius: 4px;
        border: 1px solid rgba(34, 36, 38, 0.15);
        background-color: white;

        .dropdown__search-bar-input {
          width: 100%;
          padding: 8px 8px;
          background-color: unset;
        }

        .dropdown__search-bar-input--highlighted {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .dropdown__option {
        padding: 8px;
      }

      .dropdown__option > span {
        color: black;
      }

      .dropdown__option--is-no-results-found {
        color: colors.$grey7;
      }

      .dropdown__option--is-no-results-found {
        background-color: unset;
      }

      .dropdown__option--is-active-option {
        background-color: unset;
        font-weight: bold;
        background-color: colors.$grey5;
      }

      .dropdown__option--is-highlighted-option,
      .dropdown__option:hover {
        background-color: colors.$grey4;
      }
    }

    .select__dropdown-wrapper {
      min-width: 280px;
      z-index: 1;
      margin-top: 1px;

      input:not([type='radio']),
      textarea {
        border: 1px solid colors.$grey2;
        border-radius: 4px;
        padding: 4px 8px;
        outline: none;
        font-size: 14px;
        line-height: 16px;
        &:focus,
        &:focus-visible {
          border-color: colors.$linkBlueTransparent;
        }
      }
      .dropdown__search-bar {
        box-shadow: none;
      }
    }

    .select-with-icon__wrapper {
      height: 36px;
      .select {
        color: black;
      }
    }
  }

  //**** END SELECT ****//
}

.file-import-options-box .options-box .option-description {
  width: 65%;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 22px;
  align-items: start;
  text-align: left;
}
