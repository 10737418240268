@use '../../commons/colors';

.forgotpassword-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 480px;
  padding: 48px 64px 48px 64px;

  h2,
  p {
    text-align: left;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  p {
    color: colors.$grey7;
  }

  .forgotpassword-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
  }
}
