@use "commons/colors";
/*popup*/
.modal,
#modal {
  .popup {
    overflow: hidden;
    line-height: 30px;
    border-radius: 0 0 4px 4px;
    //-webkit-box-shadow: 0 1px 20px #000;
    //box-shadow: 0 1px 20px #000;
    min-width: 600px;
    background-color: colors.$grey5;
    margin: -200px 0 0 -250px;
    padding: 0 0px 20px 0px;
    position: fixed;
    left: 46%;
    max-width: 400px;
    z-index: 999999999;
  }

  .popup p.text-container-top {
    font-size: 18px;
    margin-top: 20px;
    padding: 0 30px;
    text-align: left;
  }

  p.button-aligned-right {
    text-align: right !important;
  }

  .popup h1 {
    overflow: visible;
    max-height: inherit;
    font-size: 24px;
    padding: 10px 10px 7px 58px;
    border-bottom: 1px solid #000;
    color: #fff;
    margin: 0 !important;
    text-align: left;
  }

  .popup h2 {
    font-weight: normal;
    font-size: 16px;
    color: black;
    display: block;
    margin: 20px 0 20px 0;
  }

  .popup a.anonymous {
    padding: 8px 18px;
    font-size: 12px;
  }

  .popup a.anonymous:visited,
  .popup a.anonymous:active {
    color: black;
  }

  .popup h1 {
    overflow: visible;
    max-height: inherit;
    font-size: 24px;
    //background: #002b5c url(/public/img/logo-onlycat-white.svg) 12px 12px no-repeat;
    padding: 10px 10px 7px 64px;
    background-size: 40px;
    /* border-bottom: 1px solid #000; */
    color: #fff;
    margin: 0 !important;
    text-align: left;
    font-family: 'calibri', Arial, Helvetica, sans-serif;
  }

  .popup .x-popup {
    color: #fff;
    text-decoration: none;
    display: block;
    height: 30px;
    font-size: 20px;
    padding-top: 10px;
    float: right;
    margin: 0 10px 0 0;
    background-size: 22px;
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .x-popup:before {
    content: '\f057';
  }

  .inner {
    width: 45px;
    border-right: 1px solid #003366;
    border-radius: 0 0 0 6px;
  }

  .btn-ok,
  .btn-cancel {
    color: #fff;
    background: colors.$translatedBlue;
    font-weight: bold;
    text-decoration: none;
    padding: 8px 10px;
    border-radius: 2px;
    font-size: 18px;
    margin-right: 3px;
    cursor: pointer;
  }
  .btn-ok:hover {
    background-color: colors.$translatedBlueHover;
  }
  .btn-ok:active {
    background-color: colors.$translatedBlueActive;
  }

  .btn-cancel {
    color: colors.$grey1 !important;
    background: white !important;
    border: 1px solid colors.$grey1 !important;
  }

  .btn-cancel:hover {
    cursor: pointer;
    background-color: colors.$grey3 !important;
  }
}
