@use "../../commons/colors";

.switch-container-outer {
  display: flex;
  gap: 10px;
  align-items: center;
  .switch-container-disabled,
  .switch-container-active,
  .switch-container-inactive {
    font-size: 18px;
    color: colors.$grey6;
  }
  .switch-container-active {
    color: colors.$linkBlue;
  }
}
.switch-container {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;

  input {
    appearance: none;
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    background-color: colors.$grey7;
    transition: background-color 0.2s ease-out;

    &::before {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      bottom: 3px;
      left: 4px;
      border-radius: 50%;
      background-color: colors.$white;
      transition: transform 0.2s ease-out;
    }
  }

  input:checked + span {
    background-color: colors.$linkBlue;
  }

  input:checked + span::before {
    transform: translateX(16px);
  }

  input:disabled + span {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
