/*** Share Key Popup ***/
.share-popup-container {
  margin: 20px;
}

.share-popup-top {
  position: relative;
  width: 100%;
}

.share-popup-top h3 {
  font-size: 16px !important;
  text-align: left;
  margin-top: -5px !important;
  font-weight: normal;
}

.share-popup-top-label,
.share-popup-list-title,
.share-popup-bottom-label {
  float: left;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
}

.share-popup-container-bottom p {
  margin: 0 !important;
  text-align: left;
  padding: 0 !important;
  margin-bottom: 5px !important;
  font-size: 16px !important;
  font-weight: bold;
}

.share-popup-container-list h3 {
  font-size: 18px;
  background: #f4f4f4;
  text-align: left;
  margin: 0 !important;
  margin: 0 !important;
  padding: 5px 12px;
}

.share-popup-input-key {
  font-weight: bold;
  border: 0;
  text-align: center;
  width: 165px;
  border-bottom: 1px dashed #ccc;
}

.share-popup-input-key:focus {
  border: 0;
}

.share-popup-copy-result {
  font-size: 14px;
  clear: left;
}

.share-popup-input-result {
  font-size: 15px;
  clear: left;
  text-align: left;
  margin-top: 30px;
  color: red;
  margin-top: -4px !important;
  line-height: 20px;
}

.share-popup-container-list {
  width: 91%;
  max-height: 300px;
  position: relative;
  float: left;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  margin: 20px;
}

.share-popup-list {
  width: 100%;
  max-height: 183px;
  min-height: 45px;
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid #cacaca;
  box-shadow: inset 0 2px 2px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 2px 2px -1px rgba(0, 0, 0, 0.1);
}

.share-popup-container-bottom {
  width: 100%;
  height: 95px;
  float: left;
  margin-bottom: 30px;
}
.share-popup-container-top {
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 70px;
  button {
    border: none !important;
    height: 32px;
    width: 120px;
  }
}

input.share-popup-container-input-email {
  float: left;
  margin-left: 0px;
  width: 344px;
  height: 34px;
  padding-left: 4px;
  background-color: #fbfbfb;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.share-popup-list-item {
  width: 100%;
  height: 45px;
  float: left;
  border-bottom: 1px solid #cacaca;
}

.share-popup-list-item:last-child {
  border-bottom: none;
}

span.share-popup-item-name,
.share-popup-item-email {
  text-align: left;
  width: 100%;
  float: left;
  margin-left: 12px;
  font-size: 15px;
  line-height: 26px;
}

span.share-popup-item-email {
  font-size: 14px;
  color: #999;
  line-height: 13px;
}
