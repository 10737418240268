@use '../../commons/colors';

.translation-memory-glossary-tab {
  display: flex;
  flex-direction: column;

  .confirm-button {
    height: 26px;
    font-size: 14px !important;
    min-width: unset;
    gap: 5px !important;
    margin: 0 !important;
    padding: 0 8px;
    border-radius: unset;
  }

  .close-button {
    width: 30px;
    height: 26px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    border-radius: unset;
  }

  button {
    line-height: 1;
  }
}

.translation-memory-glossary-checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  border: 1px dashed colors.$grey8;
  padding: 10px;
  margin-bottom: 20px;
  background: colors.$white;
}

.translation-memory-glossary-checkbox-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.translation-memory-glossary-tab-table-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 10px;

  > span:first-of-type {
    flex-grow: 1;
    text-align: left;
  }
}

.translation-memory-glossary-tab-input-text {
  border-radius: 2px;
  border: 1px solid colors.$grey8;
  padding: 6px;
}

.translation-memory-glossary-tab {
  .settings-panel-table {
    .settings-panel-table-rows-container {
      background-color: white;
    }
  }

  .settings-panel-table-rowHeading {
    padding: 0 20px;
  }

  .settings-panel-table-rowHeading,
  .settings-panel-row-content {
    grid-template-columns: 8% 5% minmax(0, 1fr) 20% 5% 14% 18%;
  }

  .settings-panel-row {
    border: unset;

    .row-content-default-memory {
      grid-template-columns: 8% 5% minmax(0, 1fr) 5% 14% 18%;
      padding: 0 10px !important;

      > :last-child {
        grid-column: 6;
      }
    }
    .row-content-create-resource {
      grid-template-columns: 100%;
      padding: 0 10px;

      > form {
        padding: 0 !important;
        background-color: unset;

        > :last-child {
          grid-column: 7;
        }
      }
    }
  }

  .settings-panel-row-extra-content {
    border: none;
  }

  .settings-panel-row-content {
    background-color: colors.$white;
  }

  .settings-panel-row-active {
    padding: 10px;
    background-color: colors.$transparentBlue;
  }

  .settings-panel-table-rowHeading-column {
    text-align: center;
    padding: 0;
  }
}

.translation-memory-glossary-tab {
  .settings-panel-table {
    .settings-panel-row-content:not(.row-content-create-resource):not(
        .row-content-default-memory
      )
      > :last-child {
      grid-column: 7;
    }
  }

  .settings-panel-table:not(.translation-memory-glossary-tab-active-table) {
    .settings-panel-row {
      padding: 0 10px;
    }
    .settings-panel-row-content {
      padding: 0 10px;
    }
  }

  .settings-panel-row-content > *:not(.settings-panel-row-drag-handle) {
    padding: 0;
  }
}

.translation-memory-glossary-tab-inactive-resources {
  margin-top: 20px;
}

.tm-key-lookup,
.tm-key-update {
  display: flex;
  justify-content: center;
}

.tm-key-add-shared-resource {
  input {
    width: 90%;
  }
}

.tm-key-row-name {
  width: 100%;
  padding: 4px;
  background-color: unset;
  border: 1px solid colors.$grey8;
  border-radius: 3px;

  &:focus,
  &:hover {
    background-color: white;
  }
}

.tm-key-row-key {
  text-align: center !important;
}

.tm-key-row-name-disabled {
  border: unset;
  &:focus,
  &:hover {
    background-color: unset;
  }
}

.tm-key-row-button-item {
  color: colors.$grey6;

  > :first-child {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.tm-key-row-menu-button {
  .menu-button-wrapper {
    > :first-child {
      min-width: 140px;
      border-radius: 2px;
    }

    > :last-child {
      border: none;
      background-color: unset;
      color: colors.$grey6;
    }
  }

  .just-button-import-tmx {
    min-width: 140px;
    border-radius: 2px;
    font-size: 16px;
    color: #000;
    background: colors.$grey4;
    padding: 4px 8px;
    text-align: center;
    border: 1px solid colors.$grey7;

    &:hover {
      background-color: colors.$grey5;
    }
  }
}

.tm-key-row-menu-button-dropdown {
  margin-left: -10px;
}

.tm-key-row-icons {
  color: colors.$grey;
}

.translation-memory-glossary-tab-buttons-group {
  display: flex;
  gap: 8px;
  justify-content: end;
}

.tm-key-create-resource-row-input {
  width: 100%;
  padding: 4px;
  border-radius: 3px;
  border: solid 1px colors.$grey3;

  &.error {
    border: solid 1px colors.$redDefault;
  }
}

.translation-memory-glossary-tab-import,
.translation-memory-glossary-tab-export,
.translation-memory-glossary-tab-delete {
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-height: 50px;

  .action-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .translation-memory-glossary-tab-label {
      gap: 220px !important;
    }
    > :first-child {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .action-form-remove-from {
    align-items: start;

    > :first-child {
      gap: 0;
      align-items: start;
      flex-direction: column;
    }
  }

  .translation-memory-glossary-tab-checkbox {
    display: flex;
    gap: 10px;
  }
  .action-form-error {
    input {
      color: colors.$redDefault;
    }
  }

  .import-files {
    margin-top: 30px;

    > ul {
      display: flex;
      flex-direction: column;
      align-items: start;

      > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .filename {
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 60%;
        }

        .filename-error {
          color: colors.$redDefault;
        }
      }

      > li:nth-child(even) {
        background-color: colors.$grey4;
      }
    }

    .loading-bar {
      width: 260px;
      height: 6px;
      border-radius: 4px;
      background-color: colors.$grey8;

      > :first-child {
        background-color: colors.$approvedGreen;
        height: 100%;
        border-radius: 4px;
      }
    }

    .import-completed {
      color: colors.$approvedGreenHover;
    }

    .message-error {
      display: flex;
      align-items: center;
      gap: 8px;
      color: colors.$redDefault;

      > button {
        padding: 4px;
      }
    }
  }

  .export-successfull {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;

    > :last-child {
      color: colors.$approvedGreenHover;
    }
  }
}

.translation-memory-glossary-tab-export {
  .translation-memory-glossary-tab-input-text {
    width: 300px;
  }
}

.translation-memory-glossary-tab-delete {
  border-top: 3px solid #ffcc01;
  background-color: colors.$orangeDefaultTransparent2;
}

.translation-memory-glossary-tab-active-table {
  .settings-panel-row {
    padding-left: 10px;
    padding-right: 10px;

    &:first-child {
      padding: 10px !important;
    }

    &:last-child:not(.settings-panel-row-dragover-half-bottom) {
      padding-bottom: 10px;
    }

    &:last-child:not(:nth-child(2)):not(
        .settings-panel-row-dragover-half-bottom
      ) {
      .settings-panel-row-active {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .tm-prioritization-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 20px;
    background-color: colors.$grey3;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .tm-prioritization-text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h4 {
      margin: 0;
      font-size: 16px;
    }

    span {
      color: colors.$grey6;
    }
  }

  .switch-container-outer {
    width: 155px;
  }
}

.tm-row-penalty {
  .tm-row-penalty-button,
  .penalty-numeric-stepper-close-button {
    border-radius: 2px;
    font-size: 16px;
    color: #000 !important;
    background: colors.$grey4 !important;
    padding: 4px 8px;
    text-align: center;
    border: 1px solid colors.$grey7 !important;
    font-size: 16px !important;

    &:hover {
      background-color: colors.$grey5 !important;
    }
  }

  .tm-row-penalty-numeric-stepper {
    display: flex;
    gap: 5px;
  }

  .penalty-numeric-stepper-close-button {
    width: 28px !important;
    height: 28px !important;
    color: colors.$grey6 !important;
  }
}

.tm-row-delete-remove-from-content {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    gap: 5px;
  }
}
